export default {
  data() {
    return {
      scrollElement: null,
      parallax: {},
      parallaxRefs: ["parallaxItem"],
      parallaxRefreshOnTabChange: false,
      parallaxOptions: {
        breakpoints: [
          480, // xs > mobile
          768, // > tablet
          1024, // > desktop
        ],
      },
    };
  },
  computed: {
    parallaxDisabled() {
      return (
        this.$device.ios ||
        this.$device.ipad ||
        this.$device.iphone ||
        this.$device.ipod
      );
    },
  },
  methods: {
    createParallax(options = this.parallaxOptions) {
      if (this.parallaxDisabled) return;
      this.parallaxRefs.forEach((ref) => {
        if (this.$refs[ref] && !this.parallax[ref]) {
          const thisParallax = this.$refs[ref];
          this.parallaxOptions.wrapper = this.scrollElement;

          console.log("%c%s", "color:teal;", "create parallax:", ref);
          this.parallax[ref] = new this.$Rellax(thisParallax, options);
        }
      });
    },
    refreshParallax(refs = this.parallaxRefs) {
      if (this.parallaxDisabled) return;
      refs.forEach((ref) => {
        if (this.$refs[ref]) {
          console.log("%c%s", "color:teal;", "refresh parallax", ref);
          this.parallax[ref].refresh();
        }
      });
    },
    destroyParallax(refs = this.parallaxRefs) {
      if (this.parallaxDisabled) return;
      refs.forEach((ref) => {
        if (this.$refs[ref] && this.parallax[ref]) {
          console.log("%c%s", "color:orange;", "destroy parallax:", ref);
          this.parallax[ref].destroy();
        }
      });
    },
    onTabChange() {
      if (this.parallaxDisabled) return;
      if (this.parallaxRefreshOnTabChange) {
        // destory the parallax
        this.destroyParallax(this.parallaxRefreshOnTabChange);

        // add a transition class
        this.parallaxRefreshOnTabChange.forEach((ref) => {
          this.$refs[ref].classList.add("parallax-item--transitioning");
        });

        // timeout to allow tab animation
        const timeout = 300;
        setTimeout(() => {
          // refresh the parallax
          this.refreshParallax(this.parallaxRefreshOnTabChange);

          // remove the transition class
          this.parallaxRefreshOnTabChange.forEach((ref) => {
            this.$refs[ref].classList.remove("parallax-item--transitioning");
          });
        }, timeout);
      }
    },
    waitForParallaxRefs(ref) {
      if (this.$refs[ref]) {
        this.createParallax();
      } else {
        setTimeout(() => {
          this.waitForParallaxRefs(ref);
        }, 300);
      }
    },
  },
  mounted() {
    if (!this.parallaxDisabled) {
      window.addEventListener("tabChange", this.onTabChange);
      this.scrollElement = document.getElementById("app-content");
    }
  },
  beforeDestroy() {
    if (!this.parallaxDisabled) {
      window.removeEventListener("tabChange", this.onTabChange);
      this.destroyParallax();
    }
  },
};
